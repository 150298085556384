import './styles/quasar.scss'
import lang from 'quasar/lang/fr.js'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css'
import '@quasar/extras/material-icons-round/material-icons-round.css'
import '@quasar/extras/material-icons-sharp/material-icons-sharp.css'
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css'
import '@quasar/extras/ionicons-v4/ionicons-v4.css'
import '@quasar/extras/mdi-v4/mdi-v4.css'
import '@quasar/extras/eva-icons/eva-icons.css'
import Meta from 'quasar/src/plugins/Meta.js';import Notify from 'quasar/src/plugins/Notify.js';

// To be used on app.use(Quasar, { ... })
export default {
    config: {
        notify: {
            position: "top",
            progress: true,
            icon: "mdi-alert-circle",
            color: "black",
            actions: [{ label: "Fermer", icon: "close", color: "white" }]
        }
    },
    plugins: {
        Notify,
        Meta,
    },
    lang: lang,
}
