import { createStore } from "vuex"
import VuexPersistence from "vuex-persist"

// modules goes there

// vuex persist
const vuexLocalStorage = new VuexPersistence ({
    key: 'vuex', // The key to store the state on in the storage provider.
    storage: window.localStorage, // or window.sessionStorage or localForage
    // Function that passes the state and returns the state with only the objects you want to store.
    // reducer: state => state,
    // Function that passes a mutation and lets you decide if it should update the state in localStorage.
    // filter: mutation => (true)
})

export default createStore({
    state() {},
    mutations: {},
    actions: {},
    modules: {},
    plugins: [
        vuexLocalStorage.plugin,
    ],
    strict: process.env.NODE_ENV !== 'development'
})
