// =======================================================================
// A P P   R O U T I N G 
// =======================================================================

import { createRouter, createWebHistory } from "vue-router"

const routes = [

    // ===================================================================
    // FR
    // ===================================================================

    // =============================
    // INDEX
    // =============================
    {
        path: "/",
        name: "French",
        component: () => import("@/views/Index.vue"),
    },

    // =============================
    // PAGES
    // =============================
    {
        path: "/page/enterprise",
        name: "Enterprise",
        component: () => import("@/views/pages/Enterprise.vue"),
    },
    {
        path: "/page/products",
        name: "Products",
        component: () => import("@/views/pages/Products.vue"),
    },
    {
        path: "/page/ingredients",
        name: "Ingredients",
        component: () => import("@/views/pages/Ingredients.vue"),
    },
    {
        path: "/page/ingredient/:id([1-9])/:name",
        name: "Ingredient",
        component: () => import("@/views/pages/Ingredient.vue"),
    },
    {
        path: "/page/experts-counselings",
        name: "ExpertsCounselings",
        component: () => import("@/views/pages/ExpertsCounselings.vue"),
    },
    {
        path: "/page/expert-counseling/:id([1-4])/:slug",
        name: "ExpertCounseling",
        component: () => import("@/views/pages/ExpertCounseling.vue"),
    },
    {
        path: "/page/contact-us",
        name: "Contact",
        component: () => import("@/views/pages/Contact.vue"),
    },

    // ===================================================================
    // EN
    // ===================================================================

    // =============================
    // INDEX
    // =============================
    {
        path: "/en",
        name: "English",
        component: () => import("@/views/en/Index.vue"),
    },

    // =============================
    // PAGES
    // =============================
    {
        path: "/page/en/enterprise",
        name: "Enterprise-En",
        component: () => import("@/views/pages/en/Enterprise.vue"),
    },
    {
        path: "/page/en/products",
        name: "Products-En",
        component: () => import("@/views/pages/en/Products.vue"),
    },
    {
        path: "/page/en/ingredients",
        name: "Ingredients-En",
        component: () => import("@/views/pages/en/Ingredients.vue"),
    },
    {
        path: "/page/en/ingredient/:id([1-9])/:name",
        name: "Ingredient-En",
        component: () => import("@/views/pages/en/Ingredient.vue"),
    },
    {
        path: "/page/en/experts-counselings",
        name: "ExpertsCounselings-En",
        component: () => import("@/views/pages/en/ExpertsCounselings.vue"),
    },
    {
        path: "/page/en/expert-counseling/:id([1-4])/:slug",
        name: "ExpertCounseling-En",
        component: () => import("@/views/pages/en/ExpertCounseling.vue"),
    },
    {
        path: "/page/en/contact-us",
        name: "Contact-En",
        component: () => import("@/views/pages/en/Contact.vue"),
    },
    
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior () {
        // always scroll to top
        return { top: 0 }
    }
});

export default router;
