// =======================================================================
// M A I N   A P P   E N T R Y 
// =======================================================================

import { createApp } from "vue"
import App from "@/App.vue"

// vue extended packages
import router from "@/router/router"
import store from "@/store/store"

// quasar
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from "@/quasar-user-options"

// initialize vue instance
const app = createApp(App)

// configuration
// eslint-disable-next-line no-unused-vars
const isDev = process.env.NODE_ENV === "development"

app.use(Quasar, quasarUserOptions)
app.use(store)
app.use(router)
app.mount("#app")
